@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@font-face {
  font-family: IranSans;
  src: url('../public/fonts/iranSans/IRANSansWeb\(FaNum).woff');
  font-weight: 100;
}

@font-face {
  font-family: IranSansMed;
  src: url('../public/fonts/iranSans/IRANSansWeb\(FaNum\)_Medium.woff');
  font-weight: 500;
}

@font-face {
  font-family: IranSansBold;
  src: url('../public/fonts/iranSans/IRANSansWeb\(FaNum\)_Bold.woff');
  font-weight: 700;
}
body {
  margin: 0;  
  font-family:'Lucida Sans Unicode' ,  IranSans;
  font-weight: 600 !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family:'Lucida Sans Unicode' ,  IranSans;
}
